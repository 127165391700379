<template>
  <div :class="{ orange: currentError }">
    <input
      v-if="!icon && type !== 'checkbox'"
      :class="inputClasses"
      v-model.lazy="computedValue"
      :type="type"
      :placeholder="placeholder"
      :spellcheck="spellcheck"
      :autocomplete="autocomplete"
      :required="required"
      :disabled="disabled"
      :min="min"
      :max="max"
      :step="step"
      :name="name"
      :readonly="readonly"
      :oninput="oninput"
      @blur="validateField"
    />
    <input
      v-if="type == 'checkbox'"
      :type="type"
      :class="'uk-checkbox ' + inputClasses"
      :disabled="disabled"
      :checked="computedValue"
      :name="name"
      @change="computedValue = $event.target.checked"
    />
    <slot v-if="!icon || type == 'checkbox'"></slot>
    <div v-if="icon" :class="'input-icon ' + inputClasses">
      <span :uk-icon="'icon:' + icon"></span>
      <input
        v-model.lazy="computedValue"
        :type="type"
        :placeholder="placeholder"
        :spellcheck="spellcheck"
        :autocomplete="autocomplete"
        :required="required"
        :disabled="disabled"
        :name="name"
        :min="min"
        :max="max"
        :step="step"
        :readonly="readonly"
        :blur="validateField"
      />
    </div>
    <transition name="fade">
      <p
        v-if="currentError && typeof currentError != 'boolean' && !onlyBooleanError"
        class="orange error col-f col-jfy-c-start uk-margin-small-top"
      >
        <span class="orange uk-margin-small-right" uk-icon="icon: warning"></span>
        {{ currentError }}
      </p>
    </transition>
  </div>
</template>
<script>
import validationMessage from '@/validationMapping'

export default {
  name: 'CustomInput',
  props: {
    inputClasses: {
      type: [String, Object, Array],
      default: ''
    },
    type: String,
    required: [String, Boolean],
    disabled: [String, Boolean],
    min: Number,
    max: Number,
    step: String,
    oninput: Function,
    value: [String, Boolean, Number],
    icon: String,
    placeholder: String,
    readonly: [String, Boolean],
    autocomplete: {
      type: String,
      default: ''
    },
    spellcheck: {
      type: String,
      default: 'inherit'
    },
    error: [String, Boolean],
    onlyBooleanError: Boolean,
    model: Object,
    name: String
  },
  data() {
    return {
      currentError: ''
    }
  },
  methods: {
    validateField() {
      if (this.model) {
        if (this.onlyBooleanError) {
          this.currentError = !!validationMessage(this.model)
          return
        }

        this.currentError = validationMessage(this.model)
      }
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.value
      },
      set(value) {
        if (this.model) {
          this.model.$touch()
        }
        this.$emit('oldInput', this.value)
        this.$emit('input', value)
        this.$emit('combinedInput', { oldValue: this.value, newValue: value })

        this.validateField()
      }
    }
  },
  watch: {
    error: function(newError, oldError) {
      this.currentError = newError
    }
  }
}
</script>

<style lang="less" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
