import i18n from './i18n'
import { validationMessage } from 'vuelidate-messages'

const messages = {
  required: () => i18n.t('validations.required'),
  checkboxRequired: () => i18n.t('validations.required'),
  minLength: $params => i18n.tc('validations.minLength', $params.minLength.min),
  maxLength: $params => i18n.tc('validations.maxLength', $params.maxLength.max),
  minValue: $params => i18n.t('validations.minValue', [$params.minValue.min]),
  maxValue: $params => i18n.t('validations.maxValue', [$params.maxValue.max]),
  between: $params => i18n.t('validations.between', [$params.between.min, $params.between.max]),
  alpha: () => i18n.t('validations.alpha'),
  alphaNum: () => i18n.t('validations.alphaNum'),
  alphaDash: () => i18n.t('validations.alphaDash'),
  numeric: () => i18n.t('validations.numeric'),
  integer: () => i18n.t('validations.integer'),
  decimal: () => i18n.t('validations.decimal'),
  email: () => i18n.t('validations.email'),
  ipAddress: () => i18n.t('validations.ipAddress'),
  macAddress: () => i18n.t('validations.macAddress'),
  url: () => i18n.t('validations.url'),
  sameAs: () => i18n.t('validations.sameAs')
}

export default (field, args) => validationMessage(messages, { dirty: false })(field)
